export function timeAgo(date) {
  const now = new Date();
  const secondsAgo = Math.floor((now - new Date(date)) / 1000);

  const units = [
    { name: "year", seconds: 31536000 },
    { name: "month", seconds: 2592000 },
    { name: "week", seconds: 604800 },
    { name: "day", seconds: 86400 },
    { name: "hour", seconds: 3600 },
    { name: "minute", seconds: 60 },
    { name: "second", seconds: 1 }
  ];

  for (let unit of units) {
    const interval = Math.floor(secondsAgo / unit.seconds);
    if (interval >= 1) {
      return interval === 1
        ? `${interval} ${unit.name} ago`
        : `${interval} ${unit.name}s ago`;
    }
  }

  return "just now";
}