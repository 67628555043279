import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../assets/images/logo-no-background.png';
import { primaryYellow } from '../assets/colors';
import { BOOKING_LINK } from '../utils/constants';

const links = [
  {
    link: '/',
    name: 'Home'
  },
  {
    link: '/services',
    name: 'Services'
  },
  {
    link: '/about',
    name: 'About'
  },
  {
    link: '/contact',
    name: 'Contact'
  }
]

function NavigationBar() {
  let location = useLocation();

  const scrollToTop = () => {
    window.scrollTo(10, 0);
  }

  return (
    <div>
      <header>
        <Navbar expand="lg" className="bg-dark navbar-dark">
          <Container>
            <Navbar.Brand href="/" style={{ padding: 0, margin: 0 }}><img src={Logo} alt="Tes Barber" height={40} /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto" activeKey={location.pathname}>
                {
                  links.map((link, idx) => (
                    <Nav.Link key={`${idx}nav`} href={link.link}>{link.name}</Nav.Link>
                  ))
                }
                <a className='nav-link' href={BOOKING_LINK} target='_blank' rel="noreferrer">Reservation</a>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <main className='min-vh-100'>
        <Outlet />
      </main>
      <footer>
        <div className='d-flex flex-wrap bg-dark justify-content-between text-light'>
          <div className='footer-containers'>
            <Link onClick={scrollToTop} to="/">
              <img src={Logo} alt="Tes Barber" height={60} />
            </Link>
            <p className='mt-3'>From our humble beginnings in North London, we've grown into a local favorite, known for our warm atmosphere, skilled barbers, and commitment to quality.</p>
          </div>
          <div className='footer-containers'>
            <h3 className='mb-3'>Address</h3>
            <a className='text-light' href="https://www.google.com/maps/search/?api=1&query=TES BARBER, 217 Seven Sisters Road, London, N4 3NG" target='_blank' rel='noreferrer'>
              <p>TES BARBER</p>
              <p>217 Seven Sisters Road</p>
              <p>London</p>
              <p>N4 3NG</p>
            </a>
          </div>
          <div className='footer-containers'>
            <h3 className='mb-3'>Links</h3>
            {
              links.map((link, idx) => (
                <p key={idx}><Link className='text-light' to={link.link} onClick={scrollToTop}>{link.name}</Link></p>
              ))
            }
            <a className='text-light' href={BOOKING_LINK} target='_blank' rel="noreferrer">Reservation</a>
          </div>
        </div>
        <div className='d-flex bg-dark justify-content-center text-light'>
          <span>© 2024 <Link onClick={scrollToTop} className='text-decoration-none' style={{ color: primaryYellow }} to="/">Tes barber</Link> All rights reserved. Developed by Adonay T.</span>
        </div>
      </footer>
    </div>
  );
}

export default NavigationBar;