import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { ABOUT } from '../utils/constants';

const aboutSlides = [{
  title: 'haircut',
  imageUrl: require('../assets/images/barber.jpg')
},
{
  title: 'fade haircut',
  imageUrl: require('../assets/images/barbershop.jpg')
},
{
  title: 'haircut shapeup',
  imageUrl: require('../assets/images/img9.jpg')
},
{
  title: 'braids',
  imageUrl: require('../assets/images/braids.jpg')
}];

const slides = [
  {
    title: 'haircut',
    imageUrl: require('../assets/images/barber.jpg')
  },
  {
    title: 'fade haircut',
    imageUrl: require('../assets/images/barbershop.jpg')
  },
  {
    title: 'haircut shapeup',
    imageUrl: require('../assets/images/img9.jpg')
  },
  {
    title: 'braids',
    imageUrl: require('../assets/images/braids.jpg')
  },
  {
    title: 'haircut barbershop tes barber',
    imageUrl: require('../assets/images/tes barber haircut.jpg')
  },
  {
    title: 'haircut barbershop tes barber',
    imageUrl: require('../assets/images/fade-haircut.jpg')
  },
  {
    title: 'haircut barbershop tes barber',
    imageUrl: require('../assets/images/crew cut.jpg')
  },
  {
    title: 'haircut barbershop tes barber',
    imageUrl: require('../assets/images/fade.jpg')
  },
  {
    title: 'Second slide',
    imageUrl: require('../assets/images/img2.jpg')
  },
  {
    title: 'haircut barbershop tes barber',
    imageUrl: require('../assets/images/img3.jpg')
  },
  {
    title: 'haircut barbershop tes barber',
    imageUrl: require('../assets/images/img1.jpg')
  },
  {
    title: 'haircut barbershop tes barber',
    imageUrl: require('../assets/images/img5.jpg')
  },
  {
    title: 'haircut barbershop tes barber',
    imageUrl: require('../assets/images/img6.jpg')
  },
  {
    title: 'haircut barbershop tes barber',
    imageUrl: require('../assets/images/img7.jpg')
  },
  {
    title: 'haircut barbershop tes barber',
    imageUrl: require('../assets/images/img8.jpg')
  },
  {
    title: 'tes barber',
    imageUrl: require('../assets/images/img4.jpg')
  },
]

function Slider({ section }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (section === ABOUT) {
      setData(aboutSlides)
    } else {
      setData(slides);
    }
  }, [section]);

  return (
    <div>
      <Carousel style={{ borderRadius: 15, overflow: 'hidden' }}>
        {
          data.map((ele, idx) => (
            <Carousel.Item key={`${idx}slider`} >
              <img
                className='d-block w-100'
                src={ele.imageUrl}
                alt={ele.title}
                style={{ height: '60vh', objectFit: 'contain' }}
              />
            </Carousel.Item>
          ))
        }
      </Carousel>
    </div>
  )
}

export default Slider
