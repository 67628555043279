
import React from 'react';
import '../styles/main-pages.css';
import '../styles/Contact.css'
import Reveal from '../components/common/Reveal';
import BookingButton from '../components/common/BookingButton';
import ContactSection from '../components/ContactSection';

function Contact() {
  return (
    <div className='page-container'>
      <div className="breadcumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcumb-wrap text-center">
                <h2>Contact us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='contact-section-container py-5'>
        <ContactSection />
        <div className='px-4 pv-5 contact-section-child-container-2'>
          <Reveal>
            <div style={{
              height: '100vh',
            }}>
              <iframe
                frameborder="0"
                referrerpolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/MAP_MODE?key=${process.env.REACT_APP_MAP_API_KEY || ''}&PARAMETERS`}
                style={{
                  width: '100%',
                  height: '100%'
                }}
                allowfullscreen
                title='map'
              ></iframe>
            </div>
          </Reveal>
        </div>
      </div>
      <div className='mt-2'>
        <div className="booking-hero">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcumb-wrap text-center">
                  <h2>Book your appointment</h2>
                  <h6>Schedule your next haircut online now.</h6>
                  <div><BookingButton /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-5'>
        {/* <h2>Our services</h2> */}
      </div>
    </div>
  )
}

export default Contact
