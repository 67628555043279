import React from 'react'
import { BOOKING_LINK } from '../../utils/constants'

function BookingButton() {
  return (
    <button className='btn custom-button' onClick={() => {
      window.open(BOOKING_LINK, '_blank')
    }}>Book now</button>
  )
}

export default BookingButton
