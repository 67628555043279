import React from 'react'
import '../styles/Home.css';
import { Link } from 'react-router-dom';
import BookingButton from './common/BookingButton';
import Reveal from './common/Reveal';
import Slider from './Slider';
import { ABOUT } from '../utils/constants';

function AboutUs() {
  const scrollToTop = () => {
    window.scrollTo(10, 0);
  }

  return (
    <div className='about-section-container'>
      <div className='icon-container'>
        <img src={require('../assets/images/scissor-icon.png')} className="scissor-img" alt="scissor" />
        <h4>About us</h4>
      </div>
      <div className='d-flex flex-column px-4 pb-5 about-section-child-container'>
        <Reveal>
          <h3 className='mb-4'>Welcome to Tes Barber</h3>
          <p>Since 2012, Tes Barber has been North London's go-to barbershop for expert grooming services. Conveniently located at <strong>217 Seven Sisters Road, N4 3NG</strong>, just minutes from <strong>Finsbury Park Station</strong>, we specialize in professional haircuts, beard grooming, facials, and hair coloring. Whether you're looking for a fresh cut or a full grooming experience, our team is dedicated to ensuring you look and feel your best.</p>
          <p>Our barbershop is easily accessible by public transportation and offers convenient parking nearby, making it a hassle-free stop for customers in Finsbury Park and surrounding areas. We serve clients from across North London, including locals and visitors seeking high-quality haircuts near Finsbury Park Station.</p>
          <div className='d-flex justify-content-between py-4'>
            <BookingButton />
          </div>
        </Reveal>
      </div>
      <div className='px-4 pv-5 about-section-child-container'>
        <Reveal>
          <Slider section={ABOUT} />
        </Reveal>
      </div>

      <div className='px-4 py-5 mt-5 about-section-child-container about-section-child-2'>
        <Reveal>
          <img src={require('../assets/images/barbershop.jpg')} className="about-img" alt="..." />
        </Reveal>
      </div>
      <div className='d-flex flex-column px-4 py-5 mt-5 about-section-child-container about-section-child-1'>
        <Reveal>
          <p>With extended working hours, we are open every day of the week, from <strong>Monday</strong> to <strong>Sunday</strong>, <strong>9:30 am</strong> to <strong>9:30 pm</strong>, allowing you to fit in a grooming session whenever it suits you. At Tes Barber, we take pride in our consistent customer satisfaction, offering personalized cuts, beard trims, and treatments that keep clients coming back.</p>
          <div >
            <p>Whether you're a resident of North London or just passing through the Finsbury Park area, visit us and discover why Tes Barber is a trusted name among barbers. Stop by our barbershop near Finsbury Park Station and leave with a fresh new look that suits your style.</p>
          </div>
          <div className='d-flex justify-content-between py-2 pe-3'>
            <span className='border-bottom lh-0 m-0'><Link onClick={scrollToTop} className='link-style' to='/about'>See more</Link></span>
          </div>
        </Reveal>
      </div>

    </div>
  )
}

export default AboutUs;
