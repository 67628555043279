import React from 'react';
import '../styles/main-pages.css';
import Logo from '../assets/images/logo-no-background.png';
import { Link } from 'react-router-dom';

function NotFound() {
  const scrollToTop = () => {
    window.scrollTo(10, 0);
  }

  return (
    <div className='page-container'>
      <div className="breadcumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcumb-wrap text-center">
                <h2>404</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='not-found-container'>
        <h1>Page not found</h1>
        <p className='pt-4'>Sorry, we couldn't find the page you are looking for.</p>
        <p>Please go to a different page in our website.</p>
        <Link onClick={scrollToTop} to="/">
          <img src={Logo} alt="Tes Barber" style={{ maxHeight: 110, marginTop: 40 }} />
        </Link>
      </div>
    </div>
  )
}

export default NotFound
