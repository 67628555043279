import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Reveal from './common/Reveal'
import { primaryYellow } from '../assets/colors'
import '../styles/Contact.css'

function ContactSection({ onHomePage }) {
  const form = useRef();

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    setLoading(true);
    emailjs
      .sendForm(process.env.REACT_APP_EMAIL_JS_SERVICE_ID, process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
      })
      .then(
        () => {
          form.current.reset();
          setSuccessMessage('Thank you for contacting us! We will contact you shortly.');
          setErrorMessage('');
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setSuccessMessage('');
          setErrorMessage('Sorry, something went wrong, please try again.');
          setLoading(false);
        },
      );
  };

  return (
    <div className={`d-flex flex-column px-4 pb-5 contact-section-child-container-1 ${onHomePage ? 'contact-on-home' : ''}`}>
      {
        onHomePage ? (
          <div className='icon-container'>
            <img src={require('../assets/images/scissor-icon.png')} className="scissor-img" alt="scissor" />
            <h4>Contact us</h4>
          </div>
        ) : null
      }
      <Reveal>
        {
          !onHomePage ? (<h3 className='mb-4'>Get in touch</h3>) : null
        }

        <div className={onHomePage ? 'd-flex flex-wrap justify-content-around' : ''}>
          <div className={onHomePage ? 'home-get-in-touch-con-1' : ''}>
            <p>We are here to help. Contact us anytime using the options below.</p>
            <div className='d-flex'>
              <a href="tel:02076193607" target='_blank' rel='noreferrer' className='contact-links'><i className="bi bi-telephone text-light"></i><p className='ps-2'>02076193607</p></a>
            </div>
            <div className='d-flex'>
              <a href="mailto:info@tesbarber.co.uk" target='_blank' rel='noreferrer' className='contact-links'><i className="bi bi-envelope text-light"></i><p className='ps-2'>info@tesbarber.co.uk</p></a>
            </div>
            <div className='d-flex'>
              <a href="https://wa.me/447935311141" target='_blank' rel='noreferrer' className='contact-links'><i className="bi bi-whatsapp text-light"></i><p className='ps-2'>07935311141</p></a>
            </div>
            <div className='d-flex'>
              <a href="https://www.instagram.com/tes__barber" target='_blank' rel="noreferrer" className='contact-links'>
                <i className="bi bi-instagram text-light"></i><p className='ps-2'>@tes__barber</p>
              </a>
            </div>
            <div className='d-flex'>
              <a href="https://www.facebook.com/people/Tes-Barber/pfbid02zbcxKMQDoowWLhj1Jcd3NJ2kLMhGrumZVdmtR2ZbQtoKdQPxdAGDsmabQsN8kFibl" target='_blank' rel="noreferrer" className='contact-links'>
                <i className="bi bi-facebook text-light"></i><p className='ps-2'>Tes Barber</p>
              </a>
            </div>
            <div className='d-flex'>
              <a href="https://youtu.be/0_ATs3ogqzw" target='_blank' rel="noreferrer" className='contact-links'>
                <i className="bi bi-youtube text-light"></i><p className='ps-2'>Tes Barber</p>
              </a>
            </div>
            <div className='d-flex'>
              <a className='contact-links' href="https://www.google.com/maps/search/?api=1&query=TES BARBER, 217 Seven Sisters Road, London, N4 3NG" target='_blank' rel='noreferrer'>
                <i className="bi bi-geo-alt text-light"></i><p className='mx-2 address-text'>217 Seven Sisters Road, London, N4 3NG</p></a>
            </div>
            <div className='d-flex'>
              <i className='bi bi-clock text-light'></i><p className='mx-2'>Monday to Sunday, 9:30 am - 9:30 pm</p>
            </div>

          </div>

          <div style={{
            border: `${primaryYellow} 1px solid`,
            borderRadius: 15,
            maxWidth: 540
          }} className={`p-3 home-get-in-touch-con-2 ${!onHomePage ? 'on-contact-page' : ''}`}>
            {
              !onHomePage ? (<h3 className='mb-3'>Contact us</h3>) : null
            }
            <form ref={form} onSubmit={sendEmail}>
              <div className="mb-3">
                <label htmlFor="name_input" className="form-label text-light">Name</label>
                <input
                  type="text"
                  className="form-control background-dark"
                  id="name_input"
                  placeholder='Jhon Doe'
                  name='user_name'
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phoneInput" className="form-label text-light">Phone</label>
                <input
                  type="tel"
                  className="form-control background-dark"
                  id="phoneInput"
                  placeholder='079...'
                  name='user_phone_number'
                />
              </div>
              <div className="mb-3">
                <label htmlFor="emailInput" className="form-label text-light">Email</label>
                <input
                  id="emailInput"
                  type="email"
                  className="form-control background-dark"
                  name="user_email"
                  aria-describedby="emailHelp"
                  placeholder='example@example.com'
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="validationTextarea" className="form-label text-light">Message</label>
                <textarea className="form-control" id="validationTextarea" placeholder="Message..." required name="message"></textarea>
              </div>
              <div className="mt-4">
                {errorMessage ? (<p style={{ color: 'red' }}>{errorMessage}</p>) : null}
                {successMessage ? (<p style={{ color: primaryYellow }}>{successMessage}</p>) : null}

                <button type="submit" className="btn custom-button mt-1" disabled={loading}>{loading ? 'Loading...' : 'Submit'}</button>
              </div>
            </form>
          </div>
        </div>
      </Reveal>
    </div>
  )
}

export default ContactSection
