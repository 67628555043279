import React from 'react';
import '../styles/main-pages.css';
import AboutUsSection from '../components/AboutUsSection';
import ServicesSection from '../components/ServicesSection';
import WhyUsSection from '../components/WhyUsSection';
import SomeOfOurWorks from '../components/SomeOfOurWorks';
import ContactSection from '../components/ContactSection';
import BookingButton from '../components/common/BookingButton';

function Home() {
  return (
    <div className='page-container'>
      <div className="home-hero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcumb-wrap text-center">
                <h2>Welcome to Tes Barber</h2>
                <h6>Where style meets profisionalism</h6>
                <h6>Get the perfect cut at the barber shop that cares about your style.</h6>
                <div><button className='btn custom-button mt-4' onClick={() => {
                  window.open('https://tesbarber.simplybook.it/v2/#book', '_blank')
                }}>Book now</button></div>
                <div className='breadcrum-links'>
                  <a href="tel:02076193607" target='_blank' rel='noreferrer' className='contact-links'><i className="bi bi-telephone text-light"></i></a>
                  <a href="https://wa.me/447935311141" target='_blank' rel='noreferrer' className='contact-links'><i className="bi bi-whatsapp text-light"></i></a>
                  <a href="https://www.instagram.com/tes__barber" target='_blank' rel="noreferrer" className='contact-links'>
                    <i className="bi bi-instagram text-light"></i>
                  </a>
                  <a href="https://www.facebook.com/people/Tes-Barber/pfbid02zbcxKMQDoowWLhj1Jcd3NJ2kLMhGrumZVdmtR2ZbQtoKdQPxdAGDsmabQsN8kFibl" target='_blank' rel="noreferrer" className='contact-links'>
                    <i className="bi bi-facebook text-light"></i>
                  </a>
                </div>
                <div className='breadcrum-links mt-3'>
                  <a className='contact-links text-light d-flex' href="https://www.google.com/maps/search/?api=1&query=TES BARBER, 217 Seven Sisters Road, London, N4 3NG" target='_blank' rel='noreferrer'>
                    <i className="bi bi-geo-alt text-light"></i><p className='my-0 mx-3 address-text'>217 Seven Sisters Road, London, N4 3NG</p></a>
                </div>
                <div className='breadcrum-links mt-3'>
                  <i className='bi bi-clock text-light'></i><p className='my-0 px-1'>Monday to Sunday, 9:30 am - 9:30 pm</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className='home-sections'>
        <AboutUsSection />
      </section>
      <section className='home-sections'>
        <ServicesSection onHomePage />
      </section>
      <div className="booking-hero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcumb-wrap text-center">
                <h2>Book your appointment</h2>
                <h6>Schedule your next haircut online now. Our online booking service is available 24/7.</h6>
                <div><button className='btn custom-button' onClick={() => {
                  window.open('https://tesbarber.simplybook.it/v2/#book', '_blank')
                }}>Book now</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className='home-sections'>
        <SomeOfOurWorks />
      </section>
      <section className='home-sections'>
        <WhyUsSection />
      </section>
      <section className='home-sections'>
        <ContactSection onHomePage />
      </section>
      <div className='mt-2'>
        <div className="booking-hero">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcumb-wrap text-center">
                  <h2>Book your appointment</h2>
                  <h6>Schedule your next haircut online now.</h6>
                  <div><BookingButton /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-5'>
        {/* <h2>Our services</h2> */}
      </div>
    </div>
  )
}

export default Home
