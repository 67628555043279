import React, { useEffect, useState } from 'react';
import '../styles/Home.css';
import Reveal from './common/Reveal';
import { Link } from 'react-router-dom';

const services = [
  {
    img: require('../assets/images/haircut-1.jpg'),
    title: 'Haircut',
    price: '25',
    description: "Variety of cuts, including Classic Haircuts, Fade & Taper, Buzz Cut, Undercut, and more tailored to suit your personal style and preferences by our professional barbers.",
    link: 'https://tesbarber.simplybook.it/v2/#book/service/2'
  },
  {
    img: require('../assets/images/wash.jpg'),
    title: 'Haircut and wash',
    price: '30',
    description: "Enjoy a fresh cut followed by a relaxing hair wash to leave you feeling refreshed. Includes premium shampoo and conditioner, blow-dry and light styling for a polished finish.",
    link: 'https://tesbarber.simplybook.it/v2/#book/service/3'
  },
  {
    img: require('../assets/images/shape up.jpg'),
    title: 'Shape up',
    price: '10',
    description: "A quick touch-up to keep your edges sharp. This service focuses on refining Hairline, Sideburns, Neckline and Beard edges.",
    link: 'https://tesbarber.simplybook.it/v2/#book/service/4'
  },
  {
    img: require('../assets/images/baby-haircut.jpg'),
    title: 'Kids haircut (3 - 10 year olds)',
    price: '15',
    description: "Gentle and professional haircuts designed for young children, ensuring a comfortable and fun experience. Includes basic trim and styles cuts for school or events.",
    link: 'https://tesbarber.simplybook.it/v2/#book/service/5'
  },
  {
    img: require('../assets/images/kids-haircut-11-15 years.jpg'),
    title: 'Kids haircut (11 - 15 year olds)',
    price: '20',
    description: "Trendy haircuts for pre-teens and teens, with attention to current styles and preferences. Options include Crew cut, Layered cut, Fade & Shap up, and much more.",
    link: 'https://tesbarber.simplybook.it/v2/#book/service/6'
  },
  {
    img: require('../assets/images/hair-coloring.jpg'),
    title: 'Haircut and colouring',
    price: '45',
    description: "A combination service where we provide a professional haircut followed by full or partial hair colouring. Oprions include Full Dye, Highlights & Lowlights, and Creative colouring.",
    link: 'https://tesbarber.simplybook.it/v2/#book/service/7',
  },
  {
    img: require('../assets/images/hair-coloring.jpg'),
    title: 'Hair colouring',
    price: '20',
    description: "Whether you’re looking for a bold transformation or a subtle touch, our professional hair colouring service offers Full Hair Dye, Highlights & Lowlights, Root Touch-ups, Balayage & Ombre, and Gray coverage. Choose from a wide range of natural shades or creative, vibrant colours to complement your style.",
    link: 'https://tesbarber.simplybook.it/v2/#book/service/8',
  },
  {
    img: require('../assets/images/beard-coloring.jpg'),
    title: 'Hair colouring with beard',
    price: '30',
    description: "Hair and beard coloring to match or complement each other. Whether you want a bold new look or to cover grays, options include Natural Shades, Custom Beard Tinting, and Matching hair and beard tones.",
    link: 'https://tesbarber.simplybook.it/v2/#book/service/9'
  },
  {
    img: require('../assets/images/beard-colouring.jpg'),
    title: 'Haircut & Hair Colouring with Beard',
    price: '55',
    description: "Transform your look with our expert Haircut & Hair Coloring services, tailored to your unique style and complete your style with precision beard grooming. Step out with confidence, knowing you look your best!",
    link: 'https://tesbarber.simplybook.it/v2/#book/service/17'
  },
  {
    img: require('../assets/images/braids.jpg'),
    title: 'Braids',
    price: '25',
    description: "Looking for professional braids for men that showcase style and precision? We specialize in various braiding techniques, from classic cornrows and box braids to intricate custom designs. Each braid is tailored to your personal style, ensuring both comfort and longevity. Price might vary based on the braid type and the time it takes.",
    link: "https://tesbarber.simplybook.it/v2/#book/service/13"
  },
  {
    img: require('../assets/images/facial.jpg'),
    title: 'Facials',
    price: '25',
    description: "Achieve healthier, more vibrant skin with our specialized facial treatments. Our facials target issues like clogged pores, excess oil, and dullness. We offer a range of facial services, including deep cleansing, exfoliation, and hydrating masks, to help rejuvenate your skin. Our goal is to help you feel refreshed and confident with clearer, smoother skin.",
    link: "https://tesbarber.simplybook.it/v2/#book/service/14"
  },
  {
    img: require('../assets/images/face-steam.jpg'),
    title: 'Face steam',
    price: '10',
    description: "A rejuvenating face steam treatment that opens your pores, helps cleanse your skin, and prepares it for a close shave or skincare treatment.",
    link: 'https://tesbarber.simplybook.it/v2/#book/service/10'
  },
  {
    img: require('../assets/images/skincare.jpg'),
    title: 'Skin care',
    price: '20',
    description: "Healthy, well-maintained skin is key to looking your best. Our men’s skin care services address common concerns such as dryness, irritation, and acne. We use premium, dermatologist-recommended products to cleanse, exfoliate, and hydrate your skin, leaving it balanced and refreshed.",
    link: "https://tesbarber.simplybook.it/v2/#book/service/15"
  },
  {
    img: require('../assets/images/makeup.jpg'),
    title: 'Hair and makeup',
    price: '50',
    description: "Whether you need a clean, polished look for an event or a bold transformation, our skilled team delivers precise haircuts and grooming styles. From fades to classic cuts, our barbers ensure your hair reflects your unique style. Complement your look with subtle or bold makeup applications tailored for special occasions, photoshoots, or simply enhancing your everyday appearance.",
    link: "https://tesbarber.simplybook.it/v2/#book/service/16"
  },
  {
    img: require('../assets/images/eyebrow-slit.jpg'),
    title: 'Eyebrow shaping',
    price: '5',
    description: "Precise eyebrow grooming and shaping to enhance your facial features.",
    link: "https://tesbarber.simplybook.it/v2/#book/service/11"
  },
  {
    img: require('../assets/images/wash.jpg'),
    title: 'Hair wash',
    price: '5',
    description: "A relaxing scalp cleanse using premium products that nourish and revitalize your hair. Perfect for a quick refresh between haircuts or after styling.",
    link: 'https://tesbarber.simplybook.it/v2/#book/service/12'
  },
]

const onHomepageServices = [
  'Haircut',
  'Haircut and wash',
  'Hair colouring with beard',
  'Hair colouring',
  'Face steam',
  'Kids haircut (3 - 10 year olds)',
  'Braids',
  'Skin care'
]

function ServicesSection({ onHomePage }) {
  const [selectedIndex, setSelectedIndex] = useState('');

  const [data, setData] = useState([]);

  const scrollToTop = () => {
    window.scrollTo(10, 0);
  }

  useEffect(() => {
    if (onHomePage) {
      const homeServices = services.filter((ele) => onHomepageServices.includes(ele.title));
      setData(homeServices);
    } else {
      setData(services);
    }
  }, [onHomePage]);

  return (
    <div className='container'>
      {
        onHomePage ? (
          <div className='icon-container'>
            <img src={require('../assets/images/scissor-icon.png')} className="scissor-img" alt="scissor" />
            <h4>Our services</h4>
          </div>
        ) : null
      }
      <div className='px-4 pv-5'>
        <div className='d-flex flex-wrap justify-content-between '>
          {
            data.map((ele, idx) => (
              <div
                key={idx}
                className="card bg-dark text-white mb-4 mx-auto card-width service-card"
                onClick={() => {
                  setSelectedIndex(idx);
                }}
                onMouseEnter={() => {
                  setSelectedIndex(idx);
                }}
                onMouseLeave={() => {
                  setSelectedIndex('');
                }}
              >
                <Reveal>
                  <img src={ele.img} className="card-img-top service-card-img" alt="..." />
                  <div className="card-body">
                    <div className='d-flex justify-content-between'>
                      <h5 className="card-title fs-4">{ele.title}</h5>
                      <p className="card-text text-nowrap"></p>
                    </div>
                    <p className="card-text">{idx === selectedIndex ? ele.description : ele.description.length > 120 ? `${ele.description.slice(0, 105)}...` : ele.description}</p>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className="card-text text-nowrap fw-bold fs-5">£ {ele.price}</p>
                      {idx === selectedIndex ?
                        (
                          <button className='btn custom-button' onClick={() => {
                            window.open(ele.link, '_blank')
                          }}>Book service</button>
                        ) : null}
                    </div>
                  </div>
                </Reveal>
              </div>
            ))
          }
        </div>

        {
          onHomePage ? (
            <div className='d-flex justify-content-end py-2 pe-3'>
              <span className='border-bottom lh-0 m-0'><Link onClick={scrollToTop} className='link-style' to='/services'>See more</Link></span>
            </div>
          ) : null
        }

      </div>
    </div>
  )
}

export default ServicesSection
