import React from 'react';
import '../styles/main-pages.css';
import BookingButton from '../components/common/BookingButton';
import ServicesSection from '../components/ServicesSection';
import WhyUsSection from '../components/WhyUsSection';

function Services() {
  return (
    <div className='page-container'>
      <div className="breadcumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcumb-wrap text-center">
                <h2>Services</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container py-5'>
        <ServicesSection />
      </div>

      <div className='mt-5'>
        <div className="booking-hero">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcumb-wrap text-center">
                  <h2>Book your appointment</h2>
                  <h6>Schedule your next haircut online now. Our online booking service is available 24/7.</h6>
                  <div><BookingButton /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <WhyUsSection />

      <div className='mt-5'>
        <div className="booking-hero">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcumb-wrap text-center">
                  <h2>Book your appointment</h2>
                  <h6>Schedule your next haircut online now. Our online booking service is available 24/7.</h6>
                  <div><BookingButton /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container py-5'>
        {/* <h2>Our services</h2> */}
      </div>

    </div>
  )
}

export default Services;
