import React from 'react'
import { CLOSING_TIME, OPENING_TIME } from '../utils/constants'
import { Carousel } from 'react-bootstrap'
import { timeAgo } from '../utils/helper'

const testimonials = [
  {
    name: 'Ebrima L Dampha',
    testimonial: 'Best barbers in town! I got my hair done with them without breaking a bank. I will definitely come back!',
    rating: 5,
    time: '2024-07-01'
  },
  {
    name: 'Som Som',
    testimonial: 'My local go to barber since I moved to London. Very pleased with the one guy who does my hair + warm towel included.',
    rating: 5,
    time: '2024-08-24'
  }
]

const points = [
  {
    icon: '',
    img: require('../assets/icons/barbers.png'),
    title: 'Expert Barbers',
    description: 'Our highly skilled barbers are experienced in the latest trends, precision haircuts, and classic styles for all hair types.'
  },
  {
    icon: 'bi bi-scissors',
    img: '',
    title: 'Premium Products',
    description: 'We use top-tier grooming products that promote healthy hair and skin, ensuring you look and feel your best.'
  },
  {
    icon: '',
    img: require('../assets/icons/beard.png'),
    title: 'Personalized Service',
    description: 'Whether it’s a fresh fade, kids’ haircut, or bold hair coloring, we tailor every service to meet your needs.'
  },
  {
    icon: 'bi bi-geo-alt',
    img: '',
    title: 'Convenient Location',
    description: 'Our location at 217 Seven Sisters Road is easily accessible, with Finsbury Park Station nearby and ample parking available.'
  },
]

function WhyUsSection() {
  return (
    <div>
      <div className='icon-container'>
        <img src={require('../assets/images/scissor-icon.png')} className="scissor-img" alt="scissor" />
        <h4>Why Choose Tes Barber?</h4>
      </div>
      <div className='px-4 pb-5'>
        <p className='text-center'>At Tes Barber, we blend years of experience with a genuine passion for grooming.</p>
        <div className='d-flex justify-content-between flex-wrap px-5 py-3'>
          {
            points.map((ele, idx) => (
              <div key={`${idx}why`} className='why-choose-us-card bg-dark p-3 my-3' >
                <div style={{ height: 70 }}>
                  {
                    ele.icon ? (
                      <i className={ele.icon}></i>
                    ) : null
                  }
                  {
                    ele.img ? (
                      <img src={ele.img} alt='why-choose-us' width={64}></img>
                    ) : null
                  }
                </div>
                <h3 className='py-1'>{ele.title}</h3>
                <p>{ele.description}</p>
              </div>
            ))
          }
        </div>
        <p className='mt-3 text-center'>Visit Tes Barber today and discover why we are North London's go-to barbershop for expert haircuts, beard grooming, facials, and hair coloring.

          We also offer the convenience of online booking, allowing you to schedule your appointment quickly and easily. Open from {OPENING_TIME} to {CLOSING_TIME} every day, we’re here whenever you need us.
        </p>
      </div>
      <div className='icon-container'>
        <img src={require('../assets/images/scissor-icon.png')} className="scissor-img" alt="scissor" />
        <h4>Testimonial</h4>
      </div>
      <div className='px-4 pb-5 d-flex justify-content-center'>
        <Carousel style={{ borderRadius: 15, overflow: 'hidden', maxWidth: 800, margin: '10 auto' }} pause="hover"
          nextIcon={null} prevIcon={null} interval={2000}>
          {
            testimonials.map((ele, idx) => (
              <Carousel.Item key={`${idx}test`} style={{
                minHeight: 260,
                maxHeight: 350
              }}>
                <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', paddingInline: '4rem' }}>
                  <span>
                    <i className="bi bi-quote text-white" />
                    {ele.testimonial}
                    <i className="bi bi-quote text-white" />
                  </span>
                  <div className="rating mt-3 mb-2">
                    <i className="bi bi-star-fill rating-stars"></i>
                    <i className="bi bi-star-fill rating-stars"></i>
                    <i className="bi bi-star-fill rating-stars"></i>
                    <i className="bi bi-star-fill rating-stars"></i>
                    <i className="bi bi-star-fill rating-stars"></i>
                  </div>

                  <h5>{ele.name}</h5>
                  <p className='time-ago-text'>{timeAgo(ele.time)}</p>
                </div>
              </Carousel.Item>
            ))
          }
        </Carousel>
      </div>
    </div>
  )
}

export default WhyUsSection
