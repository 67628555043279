import React from 'react'
import Slider from './Slider';
import Reveal from './common/Reveal';

function SomeOfOurWorks() {
  return (
    <div>
      <div className='icon-container'>
        <img src={require('../assets/images/scissor-icon.png')} className="scissor-img" alt="scissor" />
        <Reveal className="animated-text">
          <h4>Some of our works</h4>
        </Reveal>
      </div>
      <div className='px-4 pb-5'>
        <Reveal>
          <Slider />
        </Reveal>
      </div>
    </div>
  )
}

export default SomeOfOurWorks;
