import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import Router from './Router';

function App() {
  return (
    <div>
      <Router />
    </div>
  );
}

export default App;
